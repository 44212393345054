"use strict";

import Countries from "../classes/Countries";
import DonorAddress from "../classes/DonorAddress";
import Validator from "../classes/Validator";
import { utl_fmt_mny } from "../util";
import $ from "jquery";

/** @extends {AlpineComponent} */
const DonationData = {
    donation: { 
        /**
         * The payment amount requested by customer.
         * @var {number|null} amount
         */
        amount:                 null,
        
        /**
         * The currency the customer is paying in.
         * @var {string|null} currency
         */
        currency:               "GBP",

        /**
         * The project reference string.
         * @var {string|null} project
         */
        project:                null,

        sub_project:            null,

        /**
         * The reference code of the appeal - can be null.
         * @var {stroing|null} appeal
         */
        appeal:                 null,

        /**
         * The donation type, Zakat or Sadaqah.
         * @var {string|null} donation_type
         */
        donation_type:          null,

        /**
         * The frequency of donation, `one-time` or `reoccurring`.
         * @var {string|null} frequency
         */
        frequency:              "one-time",

        /**
         * If the `frequency` is not `one-off`, then this value is set to define how often payments are taken.
         * @var {string|null} frequency_reoccurring
         */
        frequency_reoccurring:  null,

        /**
         * Does this donation have an administration fee?
         * @var {boolean} has_admin_fee
         */
        has_admin_fee:          false,

        /**
         * What is the admin fee percentage?
         * @var {string|null} amount_admin_fee
         */
        amount_admin_fee:       5,

        /**
         * Is this donation being made in memory of someone?
         * @var {boolean} is_in_memory
         */
        is_in_memory:           false,

        /**
         * If the above is true, what is the name of the person?
         * @var {string|null} in_memory_name
         */
        in_memory_name:         null,

        /**
         * Is this donation being made on behalf of a company?
         * @var {boolean} is_company
         */
        is_company:             false,

        /**
         * If the above is trye, what is the name of the company?
         * @var {string|null} company_name
         */
        company_name:           null,

        /**
         * Has the user indicated they would like to enable GA? Possible values of `disagree` or `agree`.
         * @var {string|null}
         */
        is_gift_aid:            "disagree",

        /**
         * Is this payment Gift Aid eligible?
         * @var {boolean} is_gift_aid_eligible
         */
        is_gift_aid_eligible:   false,

        /**
         * The payment method.
         * @var {string|null} paywith
         */
        paywith:                null,

        /**
         * The appeal being donated to, if this is an appeal donation.
         */
        appeal_id:              null,

        /**
         * If this is an appeal donation, is it being made anonymously?
         */
        is_anonymous:           false,

        /**
         * The message to be attached to the donation, if it's towards an appeal.
         */
        appeal_message:         null,

    },

    /**
     * The donors core fields.
     */
    donor: {
        email_locked:       false,
        email:              null,
        title:              null,
        first_name:         null,
        last_name:          null,
        phone:              null,
        authenticated:      false
    },

    /**
     * Flow controller instance.
     */
    flowController:         null,

    /**
     * The current visible page.
     */
    currentPage:            "donation-details",

    /**
     * History list of `currentPage`.
     */
    passedPages:            [],

    /**
     * The amount without GA, formatted. (so just the amount, but formatted.)
     */
    ga_from:                null,

    /**
     * The amount with GA, formatted.
     */
    ga_to:                  null,

    /**
     * Are we ready to make payment?
     */
    is_donation_ready:      false,

    /**
     * Are we ready to proceed past the payment details page.
     */
    is_payment_ready:       false,

    /**
     * Are we in the midst of doing something?
     */
    is_donation_processing: false,

    project_name: "Most needed",
    sub_project_name: null,
    donation_zakat_sadaqah: "",

    contributionSettings: {
        one_off_allowed:    true,
        minimum_donation:   1,
        maximum_donation:   999999,
        donation_unit:      0.01,

        /**
         * Donation frequency stipulations
         */
        frequencies: {
            "monthly":  { key: "monthly",   value: "Monthly",   enabled: true },
            "daily":    { key: "daily",     value: "Daily",     enabled: true },
            "weekly":   { key: "weekly",    value: "Weekly",    enabled: true },
            "yearly":   { key: "yearly",    value: "Yearly",    enabled: true }
        },
    },

    /**
     * Donors address.
     */
    address: {
        line_1:             null,
        line_2:             null,
        city:               null,
        state:              null,
        postal_code:        null,
        country_code:       null,
        one_line:           null,
        manual:             false,
        save:               false,

        /**
         * Clears all of the address fields.
         */
        clear: function () { 
            this.line_1         = null;
            this.line_2         = null;
            this.city           = null;
            this.state          = null;
            this.postal_code    = null;
            this.country_code   = null;
        },

        /**
         * Determines if the entered address is valid for backend storage.
         * @returns {boolean}
         */
        isValid: function () { 
            var hasInvalid = false;

            [ "line_1", "city", "postal_code", "country_code" ].forEach( ( function ( field ) { 
                if ( hasInvalid ) {
                    return;
                }

                if ( ! Validator.isTextValid( this[ field ], false, false ) )  {
                    hasInvalid = true;
                }
            } ).bind( this ) );

            return ! hasInvalid;
        }
    },

    /**
     * Donors contact preferences.
     */
    preferences: {
        all:                false,
        email:              false,
        phone:              false,
        letter:             false
    },

    /**
     * Alpine component initialise.
     */
    init: function () {
        this.onChangeAmount();

        this.$watch( "donation.amount", this.onChangeDonationAmount.bind( this ) );
        this.$watch( "donation.is_company", this.updateGAStatus.bind( this ) );
        this.$watch( "donation.paywith", this.updatePaymentMethod.bind( this ) );
        this.$watch( "preferences.all", this.onSetPreferencesAll.bind( this ) );
        this.$watch( "donation.project", this.onChangeProject.bind( this ) );
        this.$watch( "donation.donation_type", this.onChangeDonationZakatSadaqah.bind( this ) ); 
        this.$watch( "donation.project", this.onChangeMainProject.bind( this ) );
        this.$watch( "donation.sub_project", this.onChangeSubProject.bind( this ) );

        $( "#amount" ).on( {
            focusout: this.onFocusoutAmount.bind( this )
        } );

        // Watch all of the address fields, if one changes then we update the manual
        [   "address.line_1", 
            "address.line_2", 
            "address.city", 
            "address.state", 
            "address.postal_code", 
            "address.country_code" 
        ].forEach( ( function ( field ) {
            this.$watch( field, this.onChangeAddressField.bind( this ) );
        } ).bind( this ) );
        
        // Hack to wait for rest of app to load before pre-setting variables.
        setTimeout( ( function () {  
            const urlParams     = new URLSearchParams(window.location.search);
            const project       = urlParams.get( "project" );
            const donationType  = urlParams.get( "type" );
            const amount        = urlParams.get( "amount" );
            const sproject      = urlParams.get( "sproject" );

            if ( project ) {
                this.donation.project = project
                
                // Bad..
                setTimeout( ( function () {
                    if ( sproject ) {
                        this.donation.sub_project = sproject;
                    }
                } ).bind( this ), 500 );
            } else { // if no project set in query params, then use the first in the list as the default. We can come back to this later to define a default in the admin backend.
                const firstProject = this.flowController.app.projectData[ Object.keys( this.flowController.app.projectData )[ 0 ] ];

                if ( firstProject ) {
                    this.donation.project = firstProject.reference;
                }
            }

            if ( donationType ) {
                this.donation.donation_type = donationType
            }

            if ( amount ) {
                setTimeout( ( function () {
                    this.donation.amount = Number( amount ) > 0 ? Number( amount ) : null;
                } ).bind( this ), 500 );
            } else {
                setTimeout( ( function () {
                    this.donation.amount = 10;
                } ).bind( this ), 0 );
            }

            if ( window.appealData ) {
                this.donation.appeal_id = window.appealData?.reference;
            }
        } ).bind( this ), 1000 );
    },

    /**
     * When the donation amount is unfocussed, ensure our project-defined stipulations are set.
     */
    onFocusoutAmount() { 
        this.ensureStipulationsAreMet();
    },

    onChangeMainProject() { 
        this.setProjectStipulations( this.donation.project );
    },
    
    onChangeSubProject() {
        this.setProjectStipulations( this.donation.project, this.donation.sub_project );

        if ( ! this.flowController?.app ){
            return;
        }

        const projects = this.flowController.app.projectData;

        if ( ! projects[ this.donation.project ] ) {
            return;
        }

        let project = projects[ this.donation.project ];

        if ( Object.keys( project.subProjects ).length ) {
            let subproject = project.subProjects.find( subProject => subProject.reference === this.donation.sub_project );

            if ( !subproject ) {
                this.sub_project_name = null;
                return;
            }

            project = subproject;
        }

        if ( ! project ) {
            return;
        }

        this.sub_project_name = project.name;
    },

    setProjectStipulations( projectId, subProjectId = null ) { 
        if ( ! this.flowController?.app ){
            return;
        }

        const projects = this.flowController.app.projectData;

        if ( ! projects[ projectId ] ) {
            return;
        }

        let project = projects[ projectId ];

        if ( Object.keys( project.subProjects ).length ) {
            let subproject = project.subProjects.find( subProject => subProject.reference === subProjectId );

            if ( subproject ) {
                project = subproject;
            }
        }

        if ( ! project ) {
            return;
        }

        this.contributionSettings.frequencies.daily.enabled     = project.isDaily;
        this.contributionSettings.frequencies.weekly.enabled    = project.isWeekly;
        this.contributionSettings.frequencies.monthly.enabled   = project.isMonthly;
        this.contributionSettings.frequencies.yearly.enabled    = project.isYearly;
        this.contributionSettings.one_off_allowed               = project.isOneOff;
        this.contributionSettings.donation_unit                 = project.donationUnit;
        this.contributionSettings.minimum_donation              = project.minimumDonationAmount;
        this.contributionSettings.maximum_donation              = project.maximumDonationAmount;

        this.ensureStipulationsAreMet();
    },

    firstEnabledReoccurrence() {
        let first = "monthly";
    
        if ( ! this.contributionSettings.frequencies.monthly.enabled ) {
            first = "daily";
        }

        if ( ! this.contributionSettings.frequencies.daily.enabled ) {
            first = "weekly";
        }

        if ( ! this.contributionSettings.frequencies.weekly.enabled ) {
            first = "yearly";
        }

        return first;
    },

    allowedReoccurrenceNames() { 
        let output = [];

        if ( this.contributionSettings.frequencies.monthly.enabled ) {
            output.push( "monthly" );
        }

        if ( this.contributionSettings.frequencies.daily.enabled ) {
            output.push( "daily" );
        }

        if ( this.contributionSettings.frequencies.weekly.enabled ) {
            output.push( "weekly" );
        }

        if ( this.contributionSettings.frequencies.weekly.enabled ) {
            output.push( "yearly" );
        }

        return output;
    },

    /**
     * Defines and executes rules to make sure the users donation is within the currently set stipulations.
     * (i.e. setting only allowed to weekly, when users selection is currently on monthly.) 
     */
    ensureStipulationsAreMet() { 
        /**
         * One off
         */

        // If one-off donations are not enabled, and the user chooses one off - set the frequency to monthly as default.
        if ( ( ! this.contributionSettings.one_off_allowed ) && ( this.donation.frequency_reoccurring === "one-time" ) ) {
            this.donation.frequency = "reoccurring";
        }

        // If reoccurring donations are not enabled, ensure we're on one-off.
        if ( this.contributionSettings.one_off_allowed && this.allowedReoccurrenceNames().length === 0 ) {
            this.donation.frequency = "one-time";
        }

        /**
         * Reoccurring rules.
         */
        if ( ! this.allowedReoccurrenceNames().includes( this.donation.frequency_reoccurring ) ) {
            this.donation.frequency_reoccurring = this.firstEnabledReoccurrence();
        }

        /**
         * Donation unit.
         */
        if ( ( ! Number.isNaN( this.contributionSettings.donation_unit ) ) && ( ! Number.isNaN( this.donation.amount ) ) ) {
            var roundedValue = Math.round( this.donation.amount / this.contributionSettings.donation_unit ) * this.contributionSettings.donation_unit;
            this.donation.amount = roundedValue.toFixed( 2 );

            // Technically, the donation unit value can act as the min donation value.
            // This will break if the min donation is higher than the donation unit, however.
            if ( this.donation.amount < this.contributionSettings.donation_unit ) {
                this.donation.amount = this.contributionSettings.donation_unit;
            }
        } else 

        /**
         * Minimum donation amount.
         */
        if ( this.donation.amount < this.contributionSettings.minimum_donation ) {
            this.donation.amount = this.contributionSettings.minimum_donation;
        } else 

        /**
         * Maximum donation amount.
         */
        if ( this.donation.amount > this.contributionSettings.maximum_donation ) {
            this.donation.amount = this.contributionSettings.maximum_donation;
        }
    },

    onChangeDonationAmount() {
        $( `.gl-input` ).prop( "checked", false );
        $( `.gl-input[data-amount="${ Number( this.donation.amount ).toFixed( 0 ) }"]` )
            .prop( "checked", true );

        const newPercent = $( ".new-percent-calculation" );
        if ( ! newPercent.length ) {
            return;
        }

        const baseAmount = Number( newPercent.get( 0 ).dataset[ "raised" ] );

        if ( ( baseAmount === undefined ) || ( baseAmount === null ) ) {
            return;
        }

        const goal = newPercent.get( 0 ).dataset[ "goal" ];
        if ( goal === undefined || goal === null ) {
            return;
        }

        const addedDonation = Number( this.donation.amount );
        const calculated = ( ( ( addedDonation + baseAmount ) / goal ) * 100 );

        newPercent.css( {
            width: ( ( calculated > 100.0 ) ? 100 : calculated ) + "%"
        } );

    },

    /**
     * Called on change to the project selected - it grabs the projects name for the end "receipt" / overview thing.
     */
    onChangeProject: function () { 
        const projects = this.flowController.app.projectData;
        if (projects[ this.donation.project ].reference == 'proj_2gbHekEndTCW6AEHI5tQRSelUoF'){
            this.donation.donation_type = 'sadaqah';
            this.onChangeDonationZakatSadaqah();
        }

        if ( ! projects[ this.donation.project ] ) {
            this.project_name = "";
        } else {
            this.project_name = projects[ this.donation.project ].name;
        }
    },

    /**
     * Determine if the currently selected project has any sub-projects.
     */
    hasSubProjects: function ()  {
        this.donation.sub_project = null;

        if ( ! this.flowController?.app ) {
            return false;
        }

        const projects = this.flowController.app.projectData;

        if ( ! projects[ this.donation.project ] ) {
            return false;
        }

        if ( projects[ this.donation.project ].subProjects.length > 0 ) {
            this.donation.sub_project = projects[ this.donation.project ].subProjects[ 0 ].reference;
            return true;
        }

        return false;
    },

    /**
     * If `hasSubProjects` result is true, this loads up the list of said sub projects.
     */
    subProjectsList: function ()  {
        if ( ! this.flowController?.app ){
            return [];
        }

        const projects = this.flowController.app.projectData;

        if ( ! projects[ this.donation.project ] ) {
            return [];
        }

        return projects[ this.donation.project ].subProjects;
    },

    /**
     * Called on change to the zakat / sadaqah of the donation.
     */
    onChangeDonationZakatSadaqah: function () { 
        this.donation_zakat_sadaqah = this.donation.donation_type === "zakat" ? "Zakat" : "Sadaqah";
    },

    /**
     * Move to the next page, if we can.
     */
    nextPage: function () {
        this.flowController.nextPage();
    },

    /**
     * Move to the previous page, if we can.
     */
    previousPage: function () { 
        this.flowController.previousPage();
    },

    /**
     * Figures out which classes to apply to the stepper, based on the current step.
     */
    stepperClassesFor( step ) {
        return {
            "active":   this.currentPage === step, 
            "done":     this.passedPages.includes( step )
        };
    },
    
    /**
     * Sets the current address fields.
     * @param {string} addressUUID 
     */
    setAddress: function ( addressUUID ) {
        /** @type {Array<DonorAddress>|null} */
        const addresses = this.flowController.app.currentDonorAddresses;

        if ( ! addresses ) {
            return;
        }

        for ( let idx in addresses ) {
            const address = addresses[ idx ];
            if ( address.reference_code === addressUUID ) { 
                this.address.line_1 = address.street1;
                this.address.line_2 = address.street2;
                this.address.city   = address.city;
                this.address.state   = address.state;
                this.address.postal_code = address.postal_code;
                this.address.country_code = address.country_code;
            }
        }
    },

    /**
     * Deletes a given address.
     * @param {string} addressUUID 
     */
    deleteAddress: function ( addressUUID ) {
        $( `[data-address-uuid="${ addressUUID }"]` ).remove();
        window[ "_App" ].services.DeleteAddressService.deleteAddress( addressUUID ); // Let this run quietly.
    },

    /**
     * Called when the amount field changes 
     */
    onChangeAmount: function () { 
        this.updateGAStatus();
    },

    /**
     * Called when any of the address fields are modified.
     */
    onChangeAddressField: function () { 
        this.updateGAStatus(); // In case of country field change.

        const fields = [ 
            "line_1", 
            "line_2", 
            "city", 
            "state", 
            "postal_code", 
            "country_code" 
        ];

        // Convert the address field into a single line.
        var oneLine = "";
        fields.forEach( ( function ( field ) {
            var fieldValue = this[ "address" ][ field ];

            // Determine if the field should even be added in.
            if ( ! Validator.isTextValid( fieldValue, false, false ) ) {
                return;
            }

            // Fix country code values.
            if ( field === "country_code" ) {
                fieldValue = Countries[ fieldValue ] ?? fieldValue;
            }

            oneLine += fieldValue + ", ";
        } ).bind( this ) );

        // Set the main "Search" address into the newly formed one_line value.
        this.address.one_line = oneLine.substring( 0, oneLine.length - 2 );
    },

    /**
     * Clears all of the address fields.
     */
    clearAddress: function () {
        this.address.line_1         = null;
        this.address.line_2         = null;
        this.address.city           = null;
        this.address.state          = null;
        this.address.postal_code    = null;
        this.address.country_code   = null;
    },

    /**
     * Called when there's a status update to Gift Aid.
     */
    updateGAStatus: function () {
        this.donation.is_gift_aid_eligible = this.isGiftAidEligible();

        setTimeout( ( function () { 
            this.ga_from    = utl_fmt_mny( this.donation.amount, this.donation.currency );
            this.ga_to      = utl_fmt_mny( Number( this.donation.amount * 1.25 ).toFixed( 2 ), this.donation.currency );
        } ).bind( this ), 1 );
    },

    /**
     * Called when the Payment Method is changed.
     */
    updatePaymentMethod: function () { 
        if ( this.donation.paywith === "card" ) {
            this.is_payment_ready = false;
            $( "#stripe-card-loading" ).removeClass( "hidden" );
            this
                .flowController
                .app
                .stripeController
                .onOpenStripeCardForm();
            $( window ).on( {
                "stripe-element-ready": this.stripeElementReady.bind( this )
            } );
        } else {
            this.is_payment_ready   = true;
            this.is_donation_ready  = true;

            // Destroy stripe, in case it was started.
            $( "#stripe-card-form-wrapper" ).addClass( "hidden" );
            $( "#stripe-card-loading" ).addClass( "hidden" );
            this
                .flowController
                .app
                .stripeController
                ?.paymentElement
                ?.destroy();
        }
    },

    /**
     * Destroy the payment method.
     */
    destroyPaymentMethod: function ()  {
        if ( this.donation.paywith === "card" ) {
            console.log( "Destroying Stripe payment instance." );

            $( "#stripe-card-loading" ).addClass( "hidden" );
            
            this
                .flowController
                .app
                .stripeController
                .onCloseStripeCardForm();

            // Destroy stripe, in case it was started.
            $( "#stripe-card-form-wrapper" ).addClass( "hidden" );
            $( "#stripe-card-loading" ).addClass( "hidden" );
            this
                .flowController
                .app
                .stripeController
                ?.paymentElement
                ?.destroy();

            // Disable the current stripe ready handler.
            $( window ).off( "stripe-element-ready" );

            this.is_payment_ready = false;

            // Reset the currently selected payment type.
            this.donation.paywith = null;
        }
    },

    /**
     * Called on click to the final button.
     */
    completeDonation: function () {
        console.log( "Completing with: ", this.donation.paywith );
        
        this.is_donation_processing = true;

        switch ( this.donation.paywith ) {
        case "card": // Stripe.
            this
                .flowController
                .app
                .stripeController
                .onClickConfirm()
                .then( result => console.log( result ) )
                .catch( ( function ( error ) {
                    this.is_donation_processing = false;
                } ).bind( this ) );
            break;

        case "bank-transfer": // GoCardless.
            this
                .flowController
                .app
                .goCardlessController
                .onSelectBankTransfer
                .bind( this.flowController.app.goCardlessController )();
                
            break;

        case "paypal": // PayPal.
            this
                .flowController
                .app
                .payPalController
                .onSelectPayPalPayment
                .bind( this.flowController.app.payPalController )();
            break;
        
        default: break;
        }
    },

    stripeElementReady: function () {
        this.is_payment_ready           = true;
        this.is_donation_ready          = true;
        this.is_donation_processing     = false;
        $( "#stripe-card-loading" ).addClass( "hidden" );
    },

    /**
     * Called when preferences are set to `all`.
     */
    onSetPreferencesAll: function () { 
        if ( this.preferences.all ) {
            this.preferences.email = true;
            this.preferences.letter = true;
            this.preferences.phone = true;
        } else {
            this.preferences.email = false;
            this.preferences.letter = false;
            this.preferences.phone = false;
        }
    },

    /**
     * Is the current donation data eligible to claim gift aid?
     * 
     * Gift aid eligibility requirements;
     *  - Lives in UK.
     *  - Pays tax.
     *  - Isn't donating on behalf of a company.
     */
    isGiftAidEligible: function () { 
        return ( this.address.country_code?.toUpperCase() === "GB" ) && ( ! this.is_company );
    },

    /**
     * Called on click to one-off frequency button.
     */
    oneTimeFrequencyClick: function () {
        if ( this.contributionSettings.one_off_allowed ) {
            this.donation.frequency = "one-time";
        }
    },

    /**
     * Bindings for the class for the one-off frequency button.
     */
    oneTimeFrequencyClass: function () {
        return this.donation.frequency !== "one-time" ? "!bg-gray-50 text-black" : "";
    },

    /**
     * Called on click to the reoccurring frequency select.
     */
    reoccurringFrequencyClick: function () { 
        this.donation.frequency = "reoccurring";
        if ( ! this.donation.frequency_reoccurring ) {
            this.donation.frequency_reoccurring = "daily";
        }
    },

    /**
     * Bindings for the class for the reoccurring frequency select.
     */
    reoccurringFrequencyClass: function () {
        return this.donation.frequency !== "reoccurring" ? "!bg-gray-50 !text-black" : "";
    },

    /**
     * Called on click to the "All" contact pref.
     */
    changeAllPreference: function () {
        if ( ! this.preferences.all ) { 
            this.preferences.all = true; 
            this.preferences.email = true; 
            this.preferences.phone = true; 
            this.preferences.letter = true; 
        } else { 
            this.preferences.all = false; 
            this.preferences.email = false; 
            this.preferences.phone = false; 
            this.preferences.letter = false; 
        }
    },

    /**
     * Called on click to the "Email" contact pref.
     */
    changeEmailPreference: function () {
        if ( this.preferences.email ) { 
            this.preferences.all = false; 
        }
    },

    /**
     * Called on click to the "Phone" contact pref.
     */
    changePhonePreference: function () { 
        if ( this.preferences.email ) { 
            this.preferences.all = false; 
        }
    },

    /**
     * Called on click to the "Letter" contact pref.
     */
    changeLetterPreference: function () {
        if ( this.preferences.email ) { 
            this.preferences.all = false; 
        }
    },

    /**
     * Gets the currency-formatted donation amount.
     */
    formattedAmount: function () { 
        const amount = utl_fmt_mny( this.donation.amount, this.donation.currency );

        if ( this.donation.frequency === "one-time" ) {
            return amount;
        }

        switch ( this.donation.frequency_reoccurring ) {
            case "daily":   return `${ amount }/day`;
            case "weekly":  return `${ amount }/wk`;
            case "monthly": return `${ amount }/mo`;
            case "yearly":  return `${ amount }/yr`;
            default:        return amount;
        }
    },

    /**
     * Gets the total amount not allocated to administration fees.
     */
    formattedAmountNoAdminFee: function () { 
        if ( ! this.donation.has_admin_fee ){
            return this.formattedAmount();
        }
        
        const amount = utl_fmt_mny( this.donation.amount - ( this.donation.amount * ( this.donation.amount_admin_fee / 100.0 ) ), this.donation.currency );

        if ( this.donation.frequency === "one-time" ) {
            return amount;
        }

        switch ( this.donation.frequency_reoccurring ) {
            case "daily":   return `${ amount }/day`;
            case "weekly":  return `${ amount }/wk`;
            case "monthly": return `${ amount }/mo`;
            case "yearly":  return `${ amount }/yr`;
            default:        return amount;
        }
    },

    /**
     * Gets the administration fee, formatted.
     */
    formattedAdminFeeAmount: function () { 
        const amount = utl_fmt_mny( this.donation.amount * ( this.donation.amount_admin_fee / 100.0 ), this.donation.currency );

        if ( this.donation.frequency == "one-time" ){ 
            return amount;
        }
        
        switch ( this.donation.frequency_reoccurring ) {
            case "daily":   return `${ amount }/day`;
            case "weekly":  return `${ amount }/wk`;
            case "monthly": return `${ amount }/mo`;
            case "yearly":  return `${ amount }/yr`;
            default:        return amount;
        }
    },

    /**
     * Converts the data contained within this object into a donation for network transport.
     * @returns {Object}
     */
    getDonation() {
        return JSON.parse( JSON.stringify( this ) );
    },

    /**
     * Exports the donation data.
     */
    export() {
        return JSON.parse( JSON.stringify( this.donation ) );
    }
};

export { 
    DonationData as default 
};